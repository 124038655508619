import React from "react"
import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Breadcrumb } from "components/breadcrumb"
// import { Aside } from "components/blog/aside"
// import { ShareButtons } from "components/share-buttons"
import { DefaultHeader } from "components/headers/default-header"
import { CvBtn } from "../../components/case_study/cv-btn"
import { BlogContainer } from "components/blog-container"
// import { Author } from "../../components/author"

const Spicysoft = ({ location }) => {
  const title =
    "【Twitter企業アカウントのフォロワーが運用開始から1ヶ月ちょっとで5,000人超！】エンジニアのための求人サイト「Tech4Uキャリア」立ち上げに「PARKLoT」でTwitterキャンペーンを実施。成功に導いた6つのアドバイスとは？"
  const list = [
    { title: "導入事例", link: "/showcases" },
    { title: title, link: location.pathname },
  ]
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="【Twitter企業アカウントのフォロワーが運用開始から1ヶ月ちょっとで5,000人超！】エンジニアのための求人サイト「Tech4Uキャリア」立ち上げに「PARKLoT」でTwitterキャンペーンを実施。成功に導いた6つのアドバイスとは？"
          pagedesc="【Twitter企業アカウントのフォロワーが運用開始から1ヶ月ちょっとで5,000人超！】エンジニアのための求人サイト「Tech4Uキャリア」立ち上げに「PARKLoT」でTwitterキャンペーンを実施。成功に導いた6つのアドバイスとは？"
          pageimg="/showcases/spicysoft/spicysoft-catch-image.jpg"
          pagepath="/showcases/spicysoft"
        />
        <Breadcrumb title="よくある質問" items={list} />
        <BlogContainer>
          <main>
            <div className="blog__meta">
              <Link to={`/blog`}>お役立ち情報</Link>
              本記事は10分で読むことができます
            </div>
            <h1>
              【Twitter企業アカウントのフォロワーが運用開始から1ヶ月ちょっとで5,000人超！】エンジニアのための求人サイト「Tech4Uキャリア」立ち上げに「PARKLoT」でTwitterキャンペーンを実施。成功に導いた6つのアドバイスとは？
            </h1>
            <div className="blog__sns-container">
              {/* {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )} */}
            </div>
            <time className="blog__time">2022.12.13</time>
            <figure className="blog__thumb">
              <img
                layout="fullWidth"
                placeholder="tracedSVG"
                alt="スパイシーソフトキャッチ画像"
                src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/spicysoft/spicysoft-catch-image.jpg"
              />
              <p>
                アプリの投稿共有サイトの企画・運営や、個人クリエーターのプロデュースを行うスパイシーソフト株式会社。新規事業としてITエンジニアのための求人サイト「Tech4Uキャリア」を立ち上げるにあたって、フォロワーの拡大と会員登録者獲得のため、Twitterキャンペーンを実施しました。その際に使用したのが株式会社X-HACKが提供しているSNSキャンペーンツール「PARKLoT（パークロット）」です。
              </p>
              <p>
                PARKLoTを活用することで、さまざまなSNSキャンペーンを、手間や時間をかけずすぐに実施できます。今回は、初めてのTwitterキャンペーンを成功に導いたPARKLoTの導入事例をご紹介いたします。
              </p>
            </figure>
            <div className="toc">
              <p className="toc__title">目次</p>
              <ol className="toc__list">
                <li className="toc__item">
                  <a href="#id1" className="toc__link">
                    1.
                    機能や価格に加え、成果を上げてきた実績と手厚いサポートがPARKLoTの強み
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id2" className="toc__link">
                    2. 1回のキャンペーン応募者が3,000人を超える！
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id3" className="toc__link">
                    3.
                    きめ細かいアドバイスと改善を繰り返し、回を重ねるごとに結果が上がっていった
                  </a>
                </li>
                <li className="toc__item">
                  <a href="#id4" className="toc__link">
                    4. 導入企業様の声
                  </a>
                </li>
              </ol>
            </div>
            <div>
              <h2 id="id1">
                1.
                機能や価格に加え、成果を上げてきた実績と手厚いサポートがPARKLoTの強み
              </h2>
              {/* Twitter公式アカウント画像を入れる */}
              {/* <StaticImage
                layout="fullWidth"
                placeholder="tracedSVG"
                alt="スパイシーソフトキャッチ画像"
                src="../../images/showcases/spicysoft/spicysoft-catch-image.jpg"
              /> */}
              <p>
                スパイシーソフト社では、ITエンジニアのための求人サイトの立ち上げに向けて、Twitterキャンペーンでフォロワー獲得と会員登録者を増やすことを検討していました。しかし、過去に別のサービスでキャンペーンを実施したものの、手間と時間がかかり、景品に高額商品を用意してもあまり結果が出なかったという経験がありました。そこで今回のキャンペーンを成功させるために導入したのが、SNSキャンペーンツールPARKLoTです。
              </p>
              <p>
                「PARKLoTを選んだのは、機能や価格などに加え、成果を上げてきた実績と何よりサポートの手厚さが決め手でした。Twitterキャンペーンの経験があまりなく、どんな風にキャンペーンを設計すればいいのかわからなかったのですが、私たちの目的を共有し、キャンペーンを始める前から目標達成に向けてさまざまなアドバイスをいただきました。例えば、フォロワーを短期的に増やすためには、フォロー＆リツイートキャンペーンでインスタントウィンを実施することが最も効果的であること。また、プレゼントの内容、キャンペーンの期間、開催回数、投稿のタイミング、バナーについても、色々とアドバイスをいただきました。これらのアドバイスがなかったら、キャンペーン自体できなかったかもしれません（スパイシーソフト社
                担当者様）」
              </p>
            </div>
            <div>
              <h2 id="id2">2. キャンペーン応募者が3,000人を超える！</h2>
              <p>
                今回「Tech4Uキャリア」のフォロワー拡大＆会員獲得のために実施したキャンペーンは以下の通りです。
              </p>
              <h3>◇キャンペーン概要◇</h3>
              <ul>
                <li>期間：約1ヶ月半</li>
                <li>キャンペーン開催回数：8回</li>
                <li>1回のキャンペーン期間：3日間</li>
                <li>
                  景品：①フォロー＆リツイートでAmazonギフトカード1,000円分（5名）
                  <br />
                  　　　②Tech4Uキャリア会員登録でモバイルモニター（1名）
                </li>
              </ul>
              <br />
              <div className="blog__border-highlight">
                <p>今回ご利用いただいたキャンペーン種別について詳しくは</p>
                <p>
                  ▶︎
                  <a
                    href="/case-study/twitter/instant-win-digital-gift"
                    target="_blank"
                    rel="noreferrer"
                  >
                    フォロー＆リツイート・ギフト券送付（インスタントウィン）
                  </a>
                </p>
              </div>
              <br />
              <h3>■キャンペーンの流れ</h3>
              <ol>
                <li>Twitterでフォロー＆リツイートする</li>
                <li>自動抽選でその場で当落をお知らせ</li>
                <li>当選者にはAmazonギフトカード1,000円分をプレゼント</li>
                <li>Tech4UキャリアのURLをクリックし会員登録</li>
                <li>
                  会員登録者の中から抽選で1名にモバイルモニターをプレゼント
                </li>
              </ol>
              <br />
              <p>
                その結果、キャンペーン前はほぼ0人だったフォロワー数がキャンペーン終了時には5,000人超まで増加しました。1回のキャンペーン応募者数も3,000人を超えるなど、Twitterキャンペーンが初めてにも関わらず、予想以上の成果を出すことができました。
              </p>
              <p>
                「フォロワーを増やしたいというクライアント様には、まずはフォロー＆リツイートでインスタントウィン（即時抽選）を実施することが効果的です。他にもハッシュタグをつけて投稿すると応募ができるものや、クーポンを配布するものなどありますが、このキャンペーンだとフォローとリツイートの2クリックで参加できるため、ユーザーの参加率が高まり、より多くの人に拡散されるのです（PARKLoT社）」
              </p>
            </div>
            <div>
              <h2 id="id3">
                3.
                きめ細かいアドバイスと改善を繰り返し、回を重ねるごとに結果が上がっていった
              </h2>
              <p>
                今回のキャンペーンを実施するにあたり、PARKLoTからのアドバイスは以下の内容でした。
              </p>
              <h3>◇アドバイス◇</h3>
              <ol>
                <li>キャンペーン期間は短く、こまめに何度も実施する</li>
                <li>投稿は週末よりも平日の通勤時間帯や昼休憩の時間帯にする</li>
                <li>景品はターゲットが「欲しい！」と思うものにする</li>
                <li>バナーは重要なのでこだわった方がいい</li>
                <li>毎回同じようなバナーではスルーされるので変化をつける</li>
                <li>季節性のあるキャンペーンを盛り込む</li>
              </ol>
              <p>
                1回目、2回目のキャンペーンは、スパイシーソフト社が自社でバナーを作成し、初回から2,000人のフォロワーを獲得できましたが、ターゲットであるエンジニアの獲得はあまりできませんでした。そこでPARKLoTの担当者にも相談しながら、エンジニアが本当に欲しいと思えるプレゼントを設定。それが、エンジニアが外出先でも使用できる「モバイルモニター」でした。
              </p>
              <p>
                その情報をバナーで全面的に伝えるため、バナー制作をPARKLoTに委託。AmazonギフトカードとモバイルモニターのWプレゼントだということが一目でわかるバナーを投稿したのです。
              </p>
              <div className="flex">
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="自社で作成したバナー"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/spicysoft/spicysoft-banner.png"
                  />
                  <p>（自社で作成したバナー）</p>
                </div>
                <p className="text-3xl font-bold my-auto mx-1">→</p>
                <div className="w-1/2">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="PARKLoTが制作したバナー"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/spicysoft/spicysoft-parklot-banner.jpg"
                  />
                  <p>（PARKLoTが制作したバナー）</p>
                </div>
              </div>
              <p>
                この改善によって、今回のキャンペーンのターゲットであるエンジニアの参加が増え、その後の会員登録までの導線を作ることができました。モバイルモニターの画像を入れたバナーの効果はパフォーマンスの向上に大きく貢献し、1回のキャンペーンで会員登録者が5人以上獲得できるようになったのです。
              </p>
              <p>
                さらにPARKLoTのアドバイスにより、同じようなバナーが続くと興味が薄れてしまうため、季節要因を取り入れた「ハロウィーンキャンペーン」を実施したところ、応募数が過去最高の3,000人を超えました。
              </p>
              <div className="flex justify-between">
                <div className="w-3/5">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="ハロウィーンキャンペーンバナー1"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/spicysoft/spicysoft-halloween1.png"
                  />
                </div>
                <div className="w-1/3">
                  <img
                    layout="fullWidth"
                    placeholder="tracedSVG"
                    alt="ハロウィーンキャンペーンバナー2"
                    src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/showcases/spicysoft/spicysoft-halloween2.jpg"
                  />
                </div>
              </div>
              <div>
                <h2 id="id4">4. 導入企業様の声</h2>
                <p>
                  全8回のTwitterキャンペーン後には、スパイシーソフト社の担当者様から、以下のような感想をいただきました。
                </p>
                <h3>(1) 優れたUI/UXで直感的に使える</h3>
                <p>
                  「初回のキャンペーンでPARKLoTを初めて使用しましたが、直感的に使えたので10分以内にキャンペーン設定が完了しました。その後、過去のキャンペーン設定を手軽にコピーできる『複製機能』が追加されたことでさらに手間が減り、今ではキャンペーン実施にあたっての設定が5分で完了します」
                </p>
                <h3>(2) スピーディーで効果の出るバナー制作</h3>
                <p>
                  「Twitterキャンペーンが初めてだったので、どんなバナーをつくれば効果が出るのかわかりませんでした。そんなときに、PARKLoTでノウハウのあるデザイナーにまるっとお任せできたので本当に助かりました。注文から約1週間でバナーを作成してくれるスピード感に加えて、新規制作で1万円、リデザインで3,000円という手軽な価格も魅力です」
                </p>
                <h3>(3) 低価格の月額制</h3>
                <p>
                  「今回Twitterキャンペーンをやってみて、目標達成のためには一度ではなく何度も実施することが大切だと実感しました。その点でも、定額で何度もキャンペーンを実施できるPARKLoTの料金システムはとても助かりますね」
                </p>
                <h3>(4) 効果が出るまで徹底的なサポートで成功に導く</h3>
                <p>
                  「PARKLoTの一番の良さは、サポートが手厚いところです。一般的なツールでは『アカウントを渡して終わり』というところが多く、キャンペーンをやってみたものの成果が出ずにやめてしまうこともあると思います。しかしPARKLoTでは、キャンペーンが成功するまで何度も改善と実施を繰り返し、目標達成まで伴走してくれました」
                </p>
                <h3>(5) 今後の展望とPARKLoTに期待すること</h3>
                <p>
                  「ある程度フォロワー数が獲得できたら、次はアカウントを育てながらエンジニアのエンゲージメントを作っていきたいです。単純にフォロワーを増やすだけなら広告を打てばいいかもしれませんが、エンジニアの会員登録を増やしつつ、同時にエンゲージメントも高められるのがPARKLoT
                  の魅力だと思います。今後もさまざまなアドバイスをいただきながら、パートナーとして一緒に改善の施策を考えて欲しいですね」
                </p>
              </div>
            </div>
            {/* <div className="blog__sns-container--foot">
              {typeof window !== "undefined" && window.location.href && (
                <ShareButtons
                  articleUrl={window.location.href}
                  articleTitle={title}
                />
              )}
            </div> */}
            <div className="case_study main">
              <CvBtn />
            </div>
          </main>
        </BlogContainer>
      </Layout>
    </>
  )
}

export default Spicysoft
